<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}

/deep/ .el-upload-dragger {
  height: 40px;
}
</style>

<template>
  <el-dialog top="4vh" v-if="m" :title="this.id == 0 ? '支付宝配置增加' : '支付宝配置修改'" :visible.sync="isShow"
             width="800px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
             custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <el-form size="medium" :rules="rules" :model="m" label-width="150px" ref="alipayServerValidateForm">
          <el-form-item label="商户类型:">
            <el-radio v-if="showType ==1" v-model="m.type" label="1">直付通服务商</el-radio>
            <el-radio v-if="showType ==2" v-model="m.type" label="2">直连商户</el-radio>
            <el-radio v-if="showType ==2" v-model="m.type" label="3">扫码授权</el-radio>
            <el-radio v-if="showType ==1" v-model="m.type" label="4">第三方应用</el-radio>
          </el-form-item>
          <el-form-item label="商户名:">
            <el-input v-model="m.mchName" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item
            v-if="m.payType ==1 || m.payType==3 || m.payType==5|| m.payType==7 || m.payType==9 || m.payType==25 || m.payType==28"
            label="商品说明:">
            <el-input v-model="m.subject" style="width: 200px"></el-input>
            -金额-
            <el-input v-model="m.subjectSuffix" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item v-if="m.type !=3" label="应用appId:">
            <el-input v-model="m.appid" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.payType ==6||m.payType ==27" label="支付宝UID:">
            <el-input v-model="m.alipayUid" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.payType ==6||m.payType ==27" label="扫码获取UID:">
            <el-image style="width: 250px; height: 250px" :src="imgUrl"></el-image>
          </el-form-item>

          <el-form-item v-if="showType ==2" label="支付方式:">
            <el-radio-group v-model="m.payType" @input="changePayType">
              <el-radio v-if="showType !=2" label="0">无产品</el-radio>
              <el-radio label="1">预授权</el-radio>
              <el-radio label="2">商家扣款</el-radio>
              <el-radio label="3">当面付</el-radio>
              <el-radio label="5">手机网站</el-radio>
              <br>
              <el-radio label="6">个码收款</el-radio>
              <el-radio label="27">黄金-个码收款</el-radio>
              <el-radio label="7">手机网站-游戏</el-radio>
              <el-radio label="8">手机网站-商城</el-radio>
              <br>
              <el-radio label="9">订单码支付</el-radio>
<!--              <el-radio label="29">订单码支付-商城</el-radio>-->
              <br>
              <el-radio label="25">当面付-游戏网站</el-radio>
              <el-radio label="26">当面付-商城网站</el-radio>
              <el-radio label="28">支付宝APP支付</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="m.payType ==7 || m.payType==8||m.payType==29|| m.payType==25|| m.payType==26"
                        label="业务域名:">
            <el-input v-model="m.host" style="width: 500px" @blur="formatUrl"></el-input>
          </el-form-item>

          <el-form-item v-if="m.type !=3" label="是否使用证书:">
            <el-radio v-model="m.isCert" label="0" @input="changePayType">证书</el-radio>
            <el-radio v-model="m.isCert" label="1" @input="changePayType">密钥</el-radio>
          </el-form-item>

          <el-form-item label="应用私钥RSA2048:" v-if="m.type !=3">
            <el-input :rows="5" type="textarea" v-model="m.privateKey" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 1 && m.type !=3" label="alipayPublicKey_RSA2:">
            <el-input :rows="5" type="textarea" v-model="m.alipayPublicKey" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 0 && m.type !=3" label="appCertPublicKey:">
            <el-input :rows="5" type="textarea" v-model="m.appPublicCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 0 && m.type !=3" label="alipayCertPublicKey_RSA2:">
            <el-input :rows="5" type="textarea" v-model="m.alipayPublicCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.isCert == 0 && m.type !=3" label="alipayRootCert:">
            <el-input :rows="5" type="textarea" v-model="m.alipayRootCertPath" style="width: 500px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.type ==2 || m.type ==3" label="限额:">
            <el-input v-model="m.limits" style="width: 500px"></el-input>
          </el-form-item>

          <!--     当面付、当面付-游戏、当面付-商城、手机网站、手机网站-游戏、手机网站-商城、订单码  的收款子号配置   -->
          <el-form-item
            v-if="m.payType ==3  || m.payType ==25|| m.payType ==26 || m.payType ==9 || m.payType ==7 || m.payType ==8 || m.payType ==29 || m.payType ==5 || m.payType ==28"
            label="收款子号配置:"
            prop="sonAppidStatus">
            <el-select v-model="m.sonAppidStatus" @change="changeDomain">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
            <el-button v-show="m.sonAppidStatus === 1" icon="el-icon-plus" size="mini" style="margin-left: 5px;"
                       @click="addDomain"></el-button>
          </el-form-item>

          <el-form-item v-for="(domain, index) in m.sonAppid" v-show="m.sonAppidStatus===1" :label="'收款子号' + index"
                        :key="domain.key"
                        :prop="'sonAppid.' + index + '.value'">
            <el-input type="text" v-model="domain.appid" placeholder="收款子号" style="width: 350px"></el-input>
            <el-input type="text" v-model="domain.weight" placeholder="轮询次数" style="width: 350px"></el-input>
            <el-button icon="el-icon-minus" @click.prevent="removeDomain(domain)" style="margin-left: 5px;"></el-button>
          </el-form-item>

          <el-form-item label="备注:">
            <el-input style="width: 500px" type="textarea" :rows="7" v-model="m.remarks">
            </el-input>
          </el-form-item>
          <el-form-item label="是否分账:"
                        v-if="m.isCert==0 && (m.payType==3 || m.payType==5 || m.payType==25|| m.payType==26)">
            <el-switch :value="m.profitShare" :active-value="1" :inactive-value="0" inactive-color="#ff4949"
                       active-text="分账"
                       inactive-text="不分账" @change="setProfitShare"></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    var checkPollingStatus = (rule, value, callback) => {
      if (this.m.sonAppidStatus === 0) {
        return callback()
      }
      if (this.m.sonAppid == null || this.m.sonAppid.length === 0) {
        return callback(new Error('请添加权重规则'))
      }
      callback()
    }
    return {
      isShow: false,
      id: 0,
      showType: 0,
      imgUrl: 'https://render.alipay.com/p/f/fd-ixpo7iia/index.html',
      m: {
        type: '2',
        host: '',
        mchName: '',
        appid: '',
        isCert: '1',
        payType: '0',
        limits: 5000,
        alipayUid: '',
        privateKey: '',
        alipayPublicKey: '',
        appPublicCertPath: '',
        alipayPublicCertPath: '',
        alipayRootCertPath: '',
        remarks: '',
        subject: '',
        subjectSuffix: '',
        profitShare: 0,
        sonAppid: [],
        sonAppidStatus: 0,
      },
      rules: {
        sonAppidStatus: [
          { validator: checkPollingStatus, trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    // 打开
    open: function (data, showType) {
      this.id = data.id
      this.isShow = true
      this.showType = showType
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0
        this.m = {
          type: '2',
          host: '',
          mchName: '',
          appid: '',
          isCert: '1',
          payType: '0',
          limits: 5000,
          alipayUid: '',
          privateKey: '',
          alipayPublicKey: '',
          appPublicCertPath: '',
          alipayPublicCertPath: '',
          alipayRootCertPath: '',
          remarks: '',
          subject: '',
          subjectSuffix: '',
          profitShare: 0,
          sonAppid: [{
            appid: null,
            weight: null,
          }],
          sonAppidStatus: 0,
        }
      } else {
        // id 非 0 代表 是要修改 先查询一下，把旧值显示在input中
        this.m.mchName = data.mchName
        this.m.appid = data.appid
        this.m.isCert = data.isCert + ''
        this.m.type = data.type + ''
        this.m.limits = data.limits
        this.m.privateKey = data.privateKey
        this.m.alipayPublicKey = data.alipayPublicKey
        this.m.appPublicCertPath = data.appPublicCertPath
        this.m.alipayPublicCertPath = data.alipayPublicCertPath
        this.m.alipayRootCertPath = data.alipayRootCertPath
        this.m.remarks = data.remarks
        this.m.payType = data.payType + ''
        this.m.alipayUid = data.alipayUid
        this.m.host = data.host
        this.m.subject = data.subject
        this.m.subjectSuffix = data.subjectSuffix
        this.m.profitShare = data.profitShare
        this.m.sonAppid = data.sonAppid
        if (data.sonAppid == null) {
          this.m.sonAppid = []
        }
        this.m.sonAppid.forEach((item, index) => {
          this.addCheckSonAppid(index)
        })
        this.m.sonAppidStatus = data.sonAppidStatus
      }
    },
    setProfitShare: function (data) {
      this.m.profitShare = data
    },
    changePayType: function (data) {
      console.log(data)
      this.m.profitShare = 0
    },
    formatUrl (event) {
      let value = event.target.value.trim() // 去除首尾空格

      // 添加 https:// 前缀
      if (!value.startsWith('https://') && !value.startsWith('http://')) {
        this.sa.alert('请添加域名前缀http:// 或 https://')
      }

      // 移除末尾的斜杠 /
      if (value.endsWith('/')) {
        value = value.slice(0, -1)
      }

      // 更新绑定的数据
      this.m.host = value
    },
    // 提交
    ok: function () {
      // 表单验证
      console.log('submit')
      this.$refs['alipayServerValidateForm'].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.addOrUpdate()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addOrUpdate () {
      // 开始增加或修改
      let self = this
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      console.log(this.m)
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          '/v1/alipay/config/save',
          this.m,
          function () {
            self.sa.alert('添加成功', function () {
              self.$parent.f5() // 父视图刷新
              self.isShow = false
              self.claerData()
            })
          },
          defaultCfg,
        )
      } else {
        // id != 0 为修改
        this.sa.ajax(
          '/v1/alipay/config/edit/' + this.id,
          this.m,
          function () {
            self.sa.alert(
              '修改成功',
              function () {
                self.$parent.f5() // 父视图刷新
                self.isShow = false
                self.claerData()
              },
              defaultCfg,
            )
          },
          defaultCfg,
        )
      }
    },
    claerData () {
      Object.keys(this.m).forEach((key) => {
        this.m[key] = ''
      })
    },
    removeDomain (item) {
      var index = this.m.sonAppid.indexOf(item)
      if (index !== -1) {
        this.m.sonAppid.splice(index, 1)
      }
    },
    addDomain () {
      this.m.sonAppid.push({
        appid: null,
        weight: null,
      })
      this.addCheckSonAppid(this.m.pollingRule.length - 1)
    },
    changeDomain (value) {
      if (value === 1 && !Array.isArray(this.m.sonAppid) || this.m.sonAppid.length <= 0) {
        this.m.sonAppid = [{
          appid: null,
          weight: null,
        }]
      } else if (value === 0 && Array.isArray(this.m.sonAppid)) {
        this.m.sonAppid = this.m.sonAppid.filter(item => item.appid !== null && item.weight !== null)
      }
    },
    addCheckSonAppid (index) {
      this.$set(this.rules, `sonAppid.${index}.value`, [
        { validator: this.checkSonAppid, trigger: 'blur' },
      ])
    },
    checkSonAppid (rule, value, callback) {
      console.log('checkSonAppid')
      const index = rule.field.split('.')[1]
      if (this.m.sonAppidStatus === 0) {
        return callback()
      }
      if (this.m.sonAppid == null || this.m.sonAppid.length === 0) {
        return callback(new Error('请添加权重规则'))
      }
      const item = this.m.sonAppid[index]

      if (item.appid == null || item.appid === '') {
        return callback(new Error('请输入收款子商户appid'))
      }
      if (item.weight == null || item.weight === '') {
        return callback(new Error('请输入轮询次数'))
      }
      return callback()
    },

  },
  created: function () {
    this.imgUrl = this.sa.cfg.api_url +
      '/v1/index/enQrcode?url=https://render.alipay.com/p/f/fd-ixpo7iia/index.html'
  },
}
</script>
